export const handleTextColor = (theme, color) => {
  switch (color) {
    case 'primary':
      return theme.primary
    case 'secondary':
      return theme.secondary
    case 'highlight':
      return theme.highlight
    case 'inherit':
      return 'inherit'
    case 'strong':
      return theme.strong
    case 'white':
      return theme.white
    case 'black':
      return theme.black
    default:
      return theme.primary
  }
}
