import _debounce from 'lodash-es/debounce'
import React, {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from 'react'
import {
  DESKTOP_BREAKPOINT,
  PHONE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from 'styles/breakpoints'

interface WindowSizeContextProps {
  width: number
  height: number
  isMobile: boolean
  isPhone: boolean
  isDesktop: boolean
  isLaptop: boolean
}

export const WindowSizeContext = createContext<WindowSizeContextProps>({
  height: undefined,
  isDesktop: true,
  isLaptop: false,
  isMobile: false,
  isPhone: false,
  width: undefined,
})

export const WindowsSizeContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  const handleResize = () => {
    // Set window width/height to state
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useLayoutEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== 'undefined') {
      // Handler to call on window resize

      // Add event listener
      window.addEventListener('resize', handleResize, { passive: true })

      // Call handler right away so state getWs updated with initial window size
      handleResize()

      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize)
    }
  }, []) // Empty array ensures that effect is only run on mount

  const isMobile = windowSize.width <= TABLET_BREAKPOINT
  const isPhone = windowSize.width <= PHONE_BREAKPOINT
  const isLaptop = windowSize.width < DESKTOP_BREAKPOINT
  const isDesktop = windowSize.width >= DESKTOP_BREAKPOINT

  return (
    <WindowSizeContext.Provider
      value={{
        width: windowSize.width,
        height: windowSize.height,
        isMobile,
        isPhone,
        isDesktop,
        isLaptop,
      }}
    >
      {children}
    </WindowSizeContext.Provider>
  )
}

export const useWindowSize = () => useContext(WindowSizeContext)
