import dynamic from 'next/dynamic'

const ModalContextProvider = dynamic(() =>
  import('./ModalContext').then((res) => res.ModalContextProvider)
)

const SearchContextProvider = dynamic(() =>
  import('./SearchContext').then((res) => res.SearchContextProvider)
)
const CompanySearchProvider = dynamic(() =>
  import('./CompanySearchContext').then((res) => res.CompanySearchProvider)
)
const MobileSearchBarContextProvider = dynamic(() =>
  import('./MobileSearchBarContext').then(
    (res) => res.MobileSearchBarContextProvider
  )
)
const BackgroundColorProvider = dynamic(() =>
  import('./BackgroundColorContext').then((res) => res.BackgroundColorProvider)
)
const WindowsSizeContextProvider = dynamic(() =>
  import('./WindowSizeContext').then((res) => res.WindowsSizeContextProvider)
)

export const ContextWrapper = ({ children }) => (
  <WindowsSizeContextProvider>
    <ModalContextProvider>
      <MobileSearchBarContextProvider>
        <SearchContextProvider>
          <CompanySearchProvider>
            <BackgroundColorProvider>{children}</BackgroundColorProvider>
          </CompanySearchProvider>
        </SearchContextProvider>
      </MobileSearchBarContextProvider>
    </ModalContextProvider>
  </WindowsSizeContextProvider>
)
