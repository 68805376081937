import styled, { css } from 'styled-components'
import {
  handleFontSize,
  handleFontWeight,
  handlePositionCentered,
  handleCapitalize,
  handleUppercase,
  handleTextColor,
  handleEllipsis,
  handleNoWrap,
  handleDisplay,
  handleClickable,
  handleLeading,
} from 'styles/typography'
import { TextProps } from '.'

export const TextContainer = styled.div<TextProps>`
  position: relative;
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ size }) => handleFontSize(size)};
  font-weight: ${({ weight }) => handleFontWeight(weight)};
  color: ${({ theme, color }) => handleTextColor(theme, color)};
  background-color: rgba(0, 0, 0, 0);
  line-height: ${({ leading }) => handleLeading(leading)};
  text-align: ${({ align }) => align};
  border: none;
  border-bottom: ${({ underlined, theme }) =>
    underlined ? '1px solid ' + theme.primary : 'none'};
  cursor: ${({ underlined }) => (underlined ? 'pointer' : 'inherit')};
  width: ${({ width }) => (width ? width : 'unset')};
  ${({ centered }) => handlePositionCentered(centered)};
  ${({ capitalize }) => handleCapitalize(capitalize)};
  ${({ uppercase }) => handleUppercase(uppercase)};
  ${({ ellipsis }) => handleEllipsis(ellipsis)};
  ${({ noWrap }) => handleNoWrap(noWrap)};
  ${({ display }) => handleDisplay(display)};
  ${({ as }) => handleClickable(as === 'button')};
  ${({ breakSpaces }) =>
    breakSpaces &&
    css`
      white-space: break-spaces;
    `}
  ${({ lines }) => {
    return (
      lines > 0 &&
      css`
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: ${lines};
        line-clamp: ${lines};
        -webkit-box-orient: vertical;
      `
    )
  }};

  ${({ hover }) =>
    hover &&
    css`
      :hover {
        cursor: pointer;
        color: ${({ theme }) => theme.highlight};
      }
    `};

  ${({ italics }) =>
    italics &&
    css`
      font-style: italic;
    `};
`

export const CustomLineThrough = styled.div`
  position: absolute;
  height: 1px;
  top: 52%;
  width: 100%;
  background-color: ${({ theme }) => theme.secondary};
`
