import { css } from 'styled-components'

export const handlePositionCentered = (centered) => {
  return centered
    ? css`
        display: block !important;
        text-align: center;
      `
    : null
}
