import styled, { css } from 'styled-components'
import { PHONE_BREAKPOINT, TABLET_BREAKPOINT } from 'styles/breakpoints'
import { Text } from 'components/Text'
import { handleFontSize, handleFontWeight } from 'styles/typography'

export const FintrosLogoContainer = styled.div<{
  collapsed?: boolean
  $christmas?: boolean
}>`
  display: flex;
  align-items: center;
  font-weight: ${() => handleFontWeight('bold')};
  font-size: ${() => handleFontSize('xl')};
  gap: ${({ $christmas }) => ($christmas ? '10px' : '0px')};

  svg {
    display: block;
    margin-right: 5px;
  }

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `};
`

export const FintrosLogoWrapper = styled.div<{
  noColor?: boolean
}>`
  img {
    /* Quick way to make the logo white */
    filter: ${({ noColor }) => noColor && 'contrast(0) brightness(200)'};
  }
`

export const FintrosLogoText = styled(Text)<{ collapsed?: boolean }>`
  opacity: ${({ collapsed }) => (collapsed ? 0 : 1)};
  display: ${({ collapsed }) => (collapsed ? 'none' : 'blocked')};

  @media screen and (max-width: ${PHONE_BREAKPOINT}px) {
    display: none;
    opacity: 1;
    visibility: ${({ collapsed }) => (collapsed ? 'hidden' : 'visible')};
  }
`
