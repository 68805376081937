import styled from 'styled-components'

const LoadingContainer = styled.div`
  position: relative;
  overflow: hidden;

  @keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.05),
      transparent
    );
    animation: loading 2s infinite;
  }
`

export const SkeletonAvatarWrapper = styled(LoadingContainer)<{
  height?: string
  width?: string
  margin?: string
}>`
  min-height: ${({ height }) => height};
  min-width: ${({ width }) => width};
  max-height: ${({ height }) => height};
  max-width: ${({ width }) => width};
  border-radius: 100%;
  background: ${({ theme }) => theme.border};
  margin: ${({ margin }) => margin};
`

export const SkeletonLineWrapper = styled(LoadingContainer)<{
  border?: boolean
  height?: string
  width?: string
  margin?: string
  borderRadius?: string
  position?: string
  zIndex?: number
  minWidth?: string
  $hidden: boolean
}>`
  display: ${({ $hidden }) => ($hidden ? 'none' : 'block')};
  position: ${({ position }) => (position ? position : 'relative')};
  overflow: hidden;
  height: ${({ height }) => height};
  width: ${({ width }) => (width ? width : '100%')};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : 'unset')};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '5px')};
  background: ${({ theme }) => theme.border};
  margin: ${({ margin }) => margin};
  border: ${({ border, theme }) =>
    border ? '3px solid ' + theme.background.primary : 'none'};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 'unset')};
`
