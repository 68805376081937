import { ApolloProvider } from '@apollo/client'
import { Analytics } from '@vercel/analytics/react'
import { apolloClient } from 'apolloClient'
import { SEO } from 'components/SEO'
import { SplashLoader } from 'components/SplashLoader'
import { ContextWrapper } from 'contexts/ContextWrapper'
import { ThemeWrapper } from 'contexts/ThemeWrapper'
import _isEmpty from 'lodash-es/isEmpty'
import { NextQueryParamProvider } from 'next-query-params'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Script from 'next/script'
import NextNProgress from 'nextjs-progressbar'
import { useEffect, useState } from 'react'
import GlobalStyle, { GlobalAppWrapper } from 'styles/globalStyles'
import { PreferenceContextProvider } from 'contexts/PreferenceContext'
import { MetaThemeColorSetter } from 'utilityComponents/MetaThemeColorSetter'
import { seoSettings } from 'components/SEO/constants'
import { DriftChat } from 'components/DriftChat'

import 'antd/dist/antd.css'

// Global fonts
import 'styles/fonts/lato.css'
import 'styles/fonts/rubik-fonts.css'
import 'styles/fonts/permanentMarker.css'

const SubdomainGuard = dynamic(
  () =>
    import('utilityComponents/SubdomainGuard').then(
      (res) => res.SubdomainGuard
    ),
  { ssr: false }
)

const AuthContextProvider = dynamic(
  () => import('contexts/AuthContext').then((res) => res.AuthContextProvider),
  { ssr: false }
)

function App({ Component, pageProps }: AppProps) {
  // Grabs meta tag info from server/static props
  const title = pageProps.data?.title
    ? pageProps.data?.title
    : seoSettings.title
  const description = pageProps.data?.description
    ? pageProps.data?.description
    : seoSettings.description
  const image = pageProps.data?.image
    ? pageProps.data?.image
    : seoSettings.social.graphic

  // For splash loader on initial load
  const [splashLoading, setSplashLoading] = useState(true)

  useEffect(() => {
    splashLoading && setTimeout(() => setSplashLoading(false), 3000)
  }, [splashLoading])

  return (
    <div>
      <SubdomainGuard />
      {/* Vercel Analytics */}
      <Analytics />
      {/* Sentry user feedback widget */}
      <Script
        src="https://browser.sentry-cdn.com/6.19.6/bundle.min.js"
        integrity="sha384-XITe7oDiyULCpVPtGc52+ISVyD2MAEbbfpNsmYcfxClZXDw+IA906MSf6rhcdf3L"
        crossOrigin="anonymous"
      ></Script>
      {/* Google Ads Tracking Pixel */}
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','AW-647225600');
      `}
      </Script>
      <SEO title={title} description={description} image={image} />
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/images/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/images/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/images/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/images/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/images/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
      </Head>
      {/* Sentry user feedback widget */}
      <Script
        id="sentry-api"
        src="https://browser.sentry-cdn.com/6.19.6/bundle.min.js"
        integrity="sha384-XITe7oDiyULCpVPtGc52+ISVyD2MAEbbfpNsmYcfxClZXDw+IA906MSf6rhcdf3L"
        crossOrigin="anonymous"
      ></Script>
      <NextQueryParamProvider>
        <ApolloProvider client={apolloClient}>
          <AuthContextProvider>
            <PreferenceContextProvider>
              <ThemeWrapper>
                {splashLoading && <SplashLoader />}
                <>
                  <NextNProgress
                    showOnShallow={false}
                    color="#0086FF"
                    options={{ showSpinner: false }}
                  />
                  <GlobalStyle />
                  <MetaThemeColorSetter>
                    <ContextWrapper>
                      <DriftChat>
                        <GlobalAppWrapper>
                          <Component {...pageProps} />
                        </GlobalAppWrapper>
                      </DriftChat>
                    </ContextWrapper>
                  </MetaThemeColorSetter>
                </>
              </ThemeWrapper>
            </PreferenceContextProvider>
          </AuthContextProvider>
        </ApolloProvider>
      </NextQueryParamProvider>
    </div>
  )
}

export default App
