import { css } from 'styled-components'

enum DISPLAY_TYPES {
  inlineBlock = 'inline-block',
}

export const handleDisplay = (display: string) => {
  return display
    ? css`
        display: ${DISPLAY_TYPES[display]};
      `
    : null
}
