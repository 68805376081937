import { zIndexes } from 'constants/zindex'
import { memo } from 'react'
import styled, { createGlobalStyle, css } from 'styled-components'
import { TABLET_BREAKPOINT } from 'styles/breakpoints'
import { backgroundBlur } from './backgroundBlur'
import { transition1 } from './transformation'
import { handleFontSize, handleFontWeight } from './typography'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box; 
  }
    
  html,
  body {
    font-family: 'Lato';
    font-size: ${() => handleFontSize('xl')};
    padding: 0;
    margin: 0;
    background-color: ${({ theme }) => theme.background.secondary};
  }

  h1, h2, h3, h4, h5, p, ul, button {
    margin: 0;
    padding: 0;
  }
    
  // styles google chromes autofill
  .label-input:-webkit-autofill,
  .label-input:-webkit-autofill:hover,
  .label-input:-webkit-autofill:focus,
  .label-input:-webkit-autofill:active {
    background: ${({ theme }) => theme.background.primary} !important;
    transition: 0s;
    -webkit-box-shadow: 0 0 0 30px ${({ theme }) =>
      theme.background.primary} inset !important;
  }

  // if this doesnt work move back to phoneNumberInput with class
  // .PhoneInputInput instead of input:
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) => theme.primary};
    -webkit-text-font-size: ${() => handleFontSize('lg')};
    -webkit-text-font-weight: ${() => handleFontWeight('light')};
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme.border} inset;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.border};
  }

  .label-input:-webkit-autofill {
    -webkit-text-fill-color: ${({ theme }) => theme.primary} !important;
    background: ${({ theme }) => theme.background.primary} !important;

    ::selection {
      -webkit-text-fill-color: ${({ theme }) => theme.white} !important;
    }
  }

  img {
    max-width:  100%;
    max-height: 100%;
    display: block;
  }

  select {
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -ms-appearance: none; /* get rid of default appearance for IE8, 9 and 10*/
  }

  ::-webkit-scrollbar {
    width: 3px !important;
    height: 3px !important;
  }

  * {
    scrollbar-width: thin !imporant;
    scrollbar-color: ${({ theme }) => theme.background.tertiary} transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background.tertiary};
  }

  ::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  .react-transform-component{
    width: 100% !important;
    height: 100% !important;
  }

  .right {
    text-align: right;
  }

  .ant-spin {
    display: block;
    color: ${({ theme }) => theme.white} !important;
  }

  .ant-spin-dot {
    display: block;
  }

  .ant-popover-inner-content {
    .ant-popover-message-title {
      color: ${({ theme }) => theme.primary} !important;
    }

    .ant-btn {
      background: #0f1629;
      color: #929292;
      border-color: #26355c !important;
      border-radius: 5px;
    }

    .ant-btn-primary{
      background: #0086FF;
      color: #FFF;
      border-color: #26355c !important;
      border-radius: 5px;
    }
  }

  //NOTE: temp removed ant-modal styles, restore from git history if needed

  //NOTE: temp removed ant-nav styles, restore from git history if needed
  
  /*****NOTE: for all selectable antd components, including textinput, dropdown, etc *****/
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: none;
    height: inherit;
    padding: 0;
  }
  .ant-select-focused {
    border: none;
  }

  .ant-input-number-focused{
    box-shadow: none !important;
    border: 1px solid ${({ theme }) => theme.highlight} !important;
  }
  /******************************************************************************** */

  .masonry-grid{
    margin: 0 auto;
  }

  .ant-btn-link[disabled]{
    color: ${({ theme }) => theme.secondary};
  }

  .ant-tooltip {
    z-index: ${zIndexes.tooltip};
  }

  .ant-tooltip-inner {
    border: 1px solid ${({ theme }) => theme.border} !important;
    background-color: ${({ theme }) => theme.background.primary} !important;
    color: ${({ theme }) => theme.primary} !important;
  }

  .ant-tooltip-arrow{
    z-index: 10;
    overflow: unset !important;

    .ant-tooltip-arrow-content {
      border-bottom: 1px solid ${({ theme }) => theme.border} !important;
      border-right: 1px solid ${({ theme }) => theme.border} !important;
      --antd-arrow-background-color: unset !important;
      background-color: ${({ theme }) => theme.background.primary} !important;
    }
  }

  .tippy-popper[x-placement^=top] [x-arrow] {
    border-top: 7px solid #DBEEF5;
  }

  .ant-message-notice-content {
    background-color: ${({ theme }) => theme.background.secondary};
    color: ${({ theme }) => theme.primary};
  }
  
  .ant-message {
    bottom: 8px;
    top: auto;
  }
  
  .ant-message-notice {
    text-align: right;
  }

  .ant-popover-inner{
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.border} !important;
    ${backgroundBlur};
  }

  .ant-popover-buttons {
    .ant-btn-default {
      background-color: ${({ theme }) => theme.background.secondary} !important;
      border: 1px solid ${({ theme }) => theme.border} !important;
      color: ${({ theme }) => theme.secondary} !important;

      :hover {
        background-color: ${({ theme }) =>
          theme.background.tertiary} !important;
        color: ${({ theme }) => theme.primary} !important;
      }
    }

    .ant-btn-primary {
      border: none !important;

      :hover {
        filter: brightness(90%);
      }
    }
  }

  .ant-popover-arrow-content{
    background-color: ${({ theme }) => theme.background.secondary} !important;
    --antd-arrow-background-color: ${({ theme }) => theme.border};
  }

  .ant-popover-inner-content{
    padding: 20px !important;
  }

  #drift-frame-controller{
    @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
      display: none !important;
    }
  }
`

export const GlobalAppWrapper = memo(styled.div`
  display: flex;
  overflow: auto;
  height: 100vh;
  justify-content: center;
  background-color: ${({ theme }) => theme.background.primary};

  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    max-height: -webkit-fill-available;
    overflow: hidden;
  }
`)

export default GlobalStyle
