import _isUndefined from 'lodash-es/isUndefined'

export const API_ENDPOINT = process.env.NEXT_PUBLIC_API_ENDPOINT
export const CMS_ENDPOINT = process.env.CMS_ENDPOINT
export const AD_API_ENDPOINT = process.env.AD_API_ENDPOINT
export const BASIC_AUTHORIZATION_TOKEN =
  'Basic ' + new Buffer('me:unrecruiter').toString('base64')

export const CMS_ACCESS_TOKEN = process.env.CMS_ACCESS_TOKEN

export const AD_AUTHORIZATION_TOKEN = process.env.ADS_AUTHORIZATION_TOKEN
export const GOOGLE_PLACES_API_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY

export const INSIGHTS_ENDPOINT = process.env.INSIGHTS_ENDPOINT
export const INSIGHTS_ACCESS_TOKEN = process.env.INSIGHTS_ACCESS_TOKEN

export const STREAM_CHAT_KEY = process.env.NEXT_PUBLIC_STREAM_KEY

export const APP_DOMAIN = process.env.NEXT_PUBLIC_APP_DOMAIN
export const VERCEL_URL = process.env.NEXT_PUBLIC_VERCEL_URL
export const GRAMMARLY_CLIENT_ID = process.env.NEXT_PUBLIC_GRAMMARLY_CLIENT_ID

export const NODE_ENV = process.env.NODE_ENV

export const DRIFT_APP_ID = process.env.NEXT_PUBLIC_DRIFT_APP_ID
