// Global colours to be used in themes
const COLOURS = {
  // Brand Colour
  blue1: '#0086FF',

  // Grays
  gray1: '#272727',
  gray2: '#989898',

  // Dark Blues
  darkBlue1: '#010317',
  darkBlue2: '#172b48',
  darkBlue3: '#929EB1',

  // Light BGs
  bgLightPrimary: '#FAFAFB',
  bgLightSecondary: '#FFFFFF',
  bgLightTertiary: '#F1F2F6',
  bgLightAlt: '#CCCCCC',
  lightBorder: '#efefef',

  // Dark BGs
  bgDarkPrimary: '#0A0C14',
  bgDarkSecondary: '#10121a',
  bgDarkTertiary: '#21262d',
  bgDarkAlt: '#CCCCCC',
  darkBorder: '#21262c',

  // Alternative Dark BGS
  // bgDarkPrimary: '#010316', // #000317
  // bgDarkSecondary: '#0a0e24',
  // bgDarkTertiary: '#192f4e',
  // bgDarkAlt: '#CCCCCC',
  // darkBorder: '#192a3e',

  // Unique Colours
  lightRed: '#CD2553',
  red: '#FF4F00',
  palered: '#EB5757',
  orange: '#F2994A',
  green: '#219653',
  white: '#FFFFFF',
  black: '#000000',
  blue: '#013991',

  // Unique Colours

  // PALETTE 1
  // lightRed: '#EB5757',
  // red: '#FF4F00',
  // orange: '#F2994A',
  // green: '#219653',
  // purple: '#A03E99',
  // pink: '#ef5777',

  // PALETTE 2
  // lightRed: '#ff5e57',
  // red: '#ff3f34',
  // orange: '#ffa801',
  // green: '#05c46b',
  // purple: '#575fcf',
  // pink: '#ef5777',

  // PALETTE 3
  // lightRed: '#ff6b6b',
  // red: '#ee5253',
  // orange: '#ff9f43',
  // green: '#1dd1a1',
  // purple: '#5f27cd',
  // pink: '#f368e0',

  // PALETTE 4
  // lightRed: '#e55039',
  // red: '#eb2f06',
  // orange: '#fa983a',
  // green: '#079992',
  purple: '#0c2461',
  pink: '#f368e0',
}

export default COLOURS
