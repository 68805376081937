import { gql } from '@apollo/client'

export const GET_USER_THEME = gql`
  query {
    currentUserData {
      id
      setting {
        id
        theme {
          id
          name
          configs
        }
      }
    }
  }
`

export interface UserThemeGQLData {
  currentUserData: {
    id: string
    setting: {
      id: string
      theme: {
        id: string
        name: string
        configs: any
      }
    }
  }[]
}

export const UPDATE_USER_THEME = gql`
  mutation ($themeName: ThemeName) {
    settingUpdate(themeName: $themeName) {
      setting {
        id
        theme {
          id
          name
          configs
        }
      }
      errors
      status
      statusCode
    }
  }
`
