import { seoSettings } from './constants'
import { NextSeo } from 'next-seo'

export interface SEOProps {
  title?: string
  description?: string
  image?: string
  url?: string
  openGraphType?: string
  schemaType?: string
  ldJsonData?: object
}

export const SEO: React.FC<SEOProps> = ({
  title = null,
  description = seoSettings.description,
  image = seoSettings.social.graphic,
  url = seoSettings.rootUrl,
  openGraphType = 'website',
  // ldJsonData = {},
}) => {
  return (
    <NextSeo
      title={title}
      description={description}
      canonical="https://www.canonical.ie/"
      openGraph={{
        type: openGraphType,
        url: url,
        title: title,
        description: description,
        images: [
          {
            url: image,
            width: 800,
            height: 600,
            alt: 'Fintros BG',
            type: 'image/jpeg',
          },
        ],
      }}
      twitter={{
        handle: '@fintroductions',
        site: '@site',
        cardType: 'summary_large_image',
      }}
    />
  )
}
