import { Image } from 'components/Image'
import {
  FintrosLogoContainer,
  FintrosLogoText,
  FintrosLogoWrapper,
} from './styles'

export interface FintrosLogoProps {
  animated?: boolean
  iconOnly?: boolean
  onClick?: () => void
  noColor?: boolean
  christmas?: boolean
  size?: number
}

export const FintrosLogo: React.FC<FintrosLogoProps> = ({
  animated = false,
  iconOnly = false,
  onClick = null,
  noColor = false,
  christmas = false,
  size = 20,
}) => {
  if (animated) {
    return (
      <FintrosLogoContainer onClick={onClick}>
        <Image
          src="/animated_fintros_logo.webp"
          alt="logo"
          loading="eager"
          height={size}
          width={size}
          nextImagePriority
          showLoadingSkeleton={false}
        />
      </FintrosLogoContainer>
    )
  }

  return (
    <FintrosLogoContainer collapsed={iconOnly} onClick={onClick} $christmas>
      <FintrosLogoWrapper noColor={noColor}>
        <Image
          src={christmas ? '/logo_christmas.png' : '/logo.svg'}
          alt="logo"
          height={christmas ? 28 : size}
          width={christmas ? 22 : size}
          noBottomRadius={christmas}
        />
      </FintrosLogoWrapper>
      <FintrosLogoText size="lg" color="highlight" collapsed={iconOnly}>
        Fintros
      </FintrosLogoText>
    </FintrosLogoContainer>
  )
}
