import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { API_ENDPOINT, BASIC_AUTHORIZATION_TOKEN } from 'constants/process'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { getCookie, removeCookie } from 'helpers/clientSideCookie'

const httpLink = ApolloLink.split(
  (operation) => operation.getContext().hasUpload,
  createUploadLink({ uri: `${API_ENDPOINT}/graphql` }),
  new HttpLink({ uri: `${API_ENDPOINT}/graphql` })
)

let headersCache: any = {}

const authLink = setContext((_, { headers }) => {
  headersCache = {
    ...headers,
    authorization: BASIC_AUTHORIZATION_TOKEN,
    'access-token': getCookie('accessToken'),
    client: getCookie('clientId'),
    uid: getCookie('userId'),
  }

  return {
    headers: headersCache,
  }
})

const errorLink = onError(
  ({ graphQLErrors, networkError, response, operation }) => {
    graphQLErrors?.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]:\n
          Message: ${message},\n 
          UserId: ${headersCache.uid},\n
          Location: ${JSON.stringify(locations)},\n 
          Query: ${operation.query.loc.source.body},\n 
          Variables: ${JSON.stringify(operation.variables, null, 2)},\n 
          Response: ${JSON.stringify(response?.data)},\n 
          Path: ${path}`
      )
    )
    if (networkError) {
      console.error(
        `[Network error]: ${networkError},\n
        at: ${JSON.stringify(networkError.stack, null, 2)}`
      )
    }
    if (networkError?.message?.includes('401')) {
      removeCookie('accessToken')
      removeCookie('clientId')
      removeCookie('userId')
      window.location.href = `/login?unauthorized=true`
    }
  }
)

export const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        fields: {
          currentUserData: {
            keyArgs: false,
            merge(existing, incoming) {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
        },
      },
    },
  }),
})
