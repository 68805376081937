enum LINE_HEIGHT {
  none = 1.05,
  tight = 1.25,
  snug = 1.375,
  normal = 1.5,
  relaxed = 1.625,
  loose = 2,
  unset = 'unset',
}

enum FONT_SIZE {
  'HERO' = 4.0,
  '13xl' = 2.5, // 40px
  '12xl' = 2.375, // 38px
  '11xl' = 2.25, // 36px
  '10xl' = 2.125, // 34px
  '9xl' = 2, // 32px
  '8xl' = 1.875, // 30px
  '7xl' = 1.75, // 28px
  '6xl' = 1.625, // 26px
  '5xl' = 1.5, // 24px
  '4xl' = 1.375, // 22px;
  '3xl' = 1.25, // 20px
  '2xl' = 1.125, // 18px
  'xl' = 1, // 16px
  lg = 0.875, // 14px
  md = 0.75, // 12px
  sm = 0.625, // 10px
  '2xs' = 0.5, // 8px
}

enum FONT_WEIGHT {
  bolder = 900,
  bold = 700,
  base = 600,
  light = 400,
}

export const handleFontSize = (size: string) => {
  return `${FONT_SIZE[size] || FONT_SIZE['md']}rem`
}

export const handleFontWeight = (weight: string) => {
  return FONT_WEIGHT[weight] || FONT_WEIGHT['base']
}

export const handleLeading = (leading) => {
  return LINE_HEIGHT[leading] || LINE_HEIGHT['normal']
}
