import COLOURS from 'styles/colours'

const commonColours = {
  white: COLOURS.white,
  black: COLOURS.black,
  red: COLOURS.red,
  lightRed: COLOURS.lightRed,
  green: COLOURS.green,
  orange: COLOURS.orange,
  blue: COLOURS.blue,
}

export const themes = [
  {
    id: '90e81043-e641-4e89-be55-d7eea590c094',
    name: 'classic_light',
    isDefault: false,
    configs: {
      is_light_mode: true,
      background: {
        primary: '#FAFAFA',
        secondary: '#FFFFFF',
        tertiary: '#F1F2F6',
        tertiaryHover: '#e5e5e5',
        alternative: '#CCCCCC',
        black: 'rgba(0,0,0,0)',
      },
      border: '#e1e1e1',
      primary: '#272727',
      secondary: '#616161',
      tertiary: '#F1F2F6',
      highlight: '#0086FF',
      white: '#FFFFFF',
      avatarBackground: {
        dark: '#FFFFFF',
        light: '#272727',
      },
      gradient: {
        primary: ['#010317', '#172b48'],
        secondary: ['#0086FF', '#172b48'],
      },
      ...commonColours,
    },
  },
  {
    id: '09c61f2d-2957-4c51-9687-7ba0cd390fbc',
    name: 'classic_black',
    isDefault: false,
    configs: {
      is_light_mode: false,
      background: {
        primary: '#0A0C14',
        secondary: '#10121a',
        tertiary: '#21262d',
        tertiaryHover: '#313741',
        alternative: '#CCCCCC',
        black: 'rgba(0,0,0,0)',
      },
      border: '#21262c',
      primary: '#FFFFFF',
      secondary: '#929EB1',
      tertiary: '#172b48',
      highlight: '#0086FF',
      avatarBackground: {
        dark: '#FFFFFF',
        light: '#192F4E',
      },
      gradient: {
        primary: ['#010317', '#172b48'],
        secondary: ['#0086FF', '#172b48'],
      },
      ...commonColours,
    },
  },
  {
    id: 'ac30830f-8691-491e-a8b8-f2da56c98e13',
    name: 'midnight_blue',
    isDefault: true,
    configs: {
      is_light_mode: false,
      background: {
        primary: '#010316',
        secondary: '#0a0e24',
        tertiary: '#192f4e',
        tertiaryHover: '#254168',
        alternative: '#CCCCCC',
        black: 'rgba(0,0,0,0)',
      },
      border: '#192a3e',
      primary: '#FFFFFF',
      secondary: '#929EB1',
      tertiary: '#172b48',
      highlight: '#0086FF',
      avatarBackground: {
        dark: '#FFFFFF',
        light: '#192F4E',
      },
      gradient: {
        primary: ['#010317', '#172b48'],
        secondary: ['#0086FF', '#172b48'],
      },
      ...commonColours,
    },
  },
]
