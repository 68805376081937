import { SkeletonAvatarWrapper, SkeletonLineWrapper } from './styles'

type SkeletonProps = {
  width: string
  minWidth?: string
  height: string
  margin: string
  border?: boolean
  borderRadius?: string
  position?: string
  zIndex?: number
  hidden?: boolean
}

export const SkeletonLine: React.FC<SkeletonProps> = ({
  width,
  height,
  margin,
  border = false,
  borderRadius,
  position,
  zIndex,
  minWidth = null,
  hidden = false,
}) => {
  return (
    <SkeletonLineWrapper
      width={width}
      height={height}
      margin={margin}
      border={border}
      borderRadius={borderRadius}
      position={position}
      zIndex={zIndex}
      minWidth={minWidth}
      $hidden={hidden}
    />
  )
}

export const SkeletonAvatar = ({ width, height, margin }) => {
  return <SkeletonAvatarWrapper width={width} height={height} margin={margin} />
}
