import { DRIFT_APP_ID } from 'constants/process'
import { LiveChatLoaderProvider, Drift } from 'react-live-chat-loader'
import { useWindowSize } from '../contexts/WindowSizeContext'

export const DriftChat: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { isMobile } = useWindowSize()

  return (
    <LiveChatLoaderProvider provider="drift" providerKey={DRIFT_APP_ID}>
      {!isMobile && <Drift />}
      {children}
    </LiveChatLoaderProvider>
  )
}
