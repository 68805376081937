export const seoSettings = {
  rootUrl: 'www.fintros.com',
  title: 'Fintros',
  description: 'Anonymous, Bias-Free Hiring.',
  social: {
    graphic:
      'https://jedi.nyc3.cdn.digitaloceanspaces.com/fintros-logos/META/meta_default.png',
    twitter: '@fintroductions',
  },
}
