import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import axios from 'axios'
import { BASIC_AUTHORIZATION_TOKEN } from 'constants/process'
import { getCookie, removeCookie } from 'helpers/clientSideCookie'

const login = async (email, password) => {
  const response = (
    await axios.post('/api/login', {
      email,
      password,
    })
  ).data

  return {
    userId: response.userId,
    clientId: response.clientId,
    accessToken: response.accessToken,
    onboardingCompletedAt: response.onboardingCompletedAt,
    success: response.success,
    confirmed: response.confirmed,
  }
}

const signup = async (email, password, accessCode) => {
  const response = (
    await axios.post('/api/signup', {
      email,
      password,
      window: window.location.origin,
      waitlist_access_code: accessCode,
    })
  ).data

  return {
    response,
  }
}

const logoutCleanup = () => {
  removeCookie('clientId')
  removeCookie('userId')
  removeCookie('accessToken')
  localStorage.clear()
}

const logout = (cb: () => void) => async () => {
  try {
    cb()
    await axios.post('/api/logout')
    logoutCleanup()
    window.location.href = '/'
  } catch (err) {
    console.error(err)
    logoutCleanup()
  }
}

const forgotPassword = async (email) => {
  const response = (
    await axios.post('/api/forgot', {
      email,
      redirect_url: `${window.location.hostname}`,
    })
  ).data
  return {
    data: response.data,
  }
}

const resetPassword = async ({ password, client, uid, accessToken }) => {
  const response = (
    await axios.post('/api/reset', {
      password,
      password_confirmation: password,
      client,
      uid,
      accessToken,
    })
  ).data

  return {
    data: response.data,
  }
}

//NOTE: only use if real time checking is needed
const checkIsLoggedIn = async () => {
  const clientId = getCookie('clientId')
  const userId = getCookie('userId')
  const accessToken = getCookie('accessToken')
  if (!userId || !clientId || !accessToken) return false

  try {
    const user = await fetch('/api/current-user').then((res) => {
      if (!res.ok) throw new Error(res.statusText)
      return res.json()
    })
    if (user) return true
  } catch (e) {
    console.error('error authenticating user: ', e, new Date())
    return false
  }
}

interface UserLocation {
  lat: string
  long: string
}

const getLocation = async (): Promise<UserLocation> => {
  try {
    const isDev = process.env.NODE_ENV !== 'production'

    if (isDev) {
      return { lat: '43.67131042480469', long: '-79.37982177734375' }
    }

    const location = (
      await fetch('/api/current-location', { method: 'POST' }).then((res) => {
        if (!res.ok) throw new Error(res.statusText)
        return res.json()
      })
    ).location

    return (
      location?.user_lat_long || {
        long: null,
        lat: null,
      }
    )
  } catch (e) {
    console.error('Error on fetching location', e)
    return {
      lat: null,
      long: null,
    }
  }
}

const handleSetUserLocation = (
  setUserLocation: Dispatch<SetStateAction<UserLocation>>,
  location: UserLocation
) => {
  setUserLocation(location)
  localStorage?.setItem('userLocation', JSON.stringify(location))
}

interface AuthStateContextProps {
  logout: () => Promise<void>
  login: typeof login
  signup: typeof signup
  forgotPassword: typeof forgotPassword
  resetPassword: typeof resetPassword
  checkIsLoggedIn: typeof checkIsLoggedIn
  isLoggedIn: boolean
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>
  userLocation: {
    lat: string
    long: string
  }
}

export const AuthStateContext = createContext<AuthStateContextProps>({
  isLoggedIn: false,
  checkIsLoggedIn: null,
  forgotPassword: null,
  login: null,
  logout: null,
  resetPassword: null,
  setIsLoggedIn: null,
  signup: null,
  userLocation: { lat: null, long: null },
})

export const AuthDispatchContext = createContext(null)

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(null)
  const location = JSON.parse(
    localStorage.getItem('userLocation') || '{ "long": null, "lat": null }'
  )
  const [userLocation, setUserLocation] = useState<UserLocation>(location)

  useEffect(() => {
    if (!isLoggedIn || (userLocation.lat && userLocation.long)) return
    getLocation().then((location) =>
      handleSetUserLocation(
        setUserLocation,
        location || { long: null, lat: null }
      )
    )
  }, [isLoggedIn])

  return (
    <AuthStateContext.Provider
      value={{
        logout: logout(() => setIsLoggedIn(false)),
        login,
        signup,
        forgotPassword,
        resetPassword,
        checkIsLoggedIn,
        isLoggedIn,
        setIsLoggedIn,
        userLocation,
      }}
    >
      {children}
    </AuthStateContext.Provider>
  )
}

export const useAuth = () => useContext(AuthStateContext)
