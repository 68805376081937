import Head from 'next/head'
import { useTheme } from 'styled-components'

export const MetaThemeColorSetter = ({ children }) => {
  const theme = useTheme()

  return (
    <>
      <Head>
        <meta
          name="theme-color"
          content={theme?.background?.primary || '#0a0c14'}
        />
      </Head>
      {children}
    </>
  )
}
