import { memo } from 'react'
import { CustomLineThrough, TextContainer } from './styles'

/**
 * @property button - Use this only when the button has the visual trait of an anchor
 */
export const TEXT_SEMANTIC_VARIANTS = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  p: 'p',
  span: 'span',
  a: 'a',
  button: 'button', //
}

export interface TextProps {
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
    | '8xl'
    | '9xl'
    | '10xl'
    | '11xl'
    | '12xl'
    | '13xl'
    | 'HERO'
  weight?: 'light' | 'base' | 'bold' | 'bolder'
  color?: string
  align?: string
  centered?: boolean
  capitalize?: boolean
  uppercase?: boolean
  ellipsis?: boolean
  noWrap?: boolean
  inline?: boolean
  display?: string
  className?: string
  as?: any
  leading?: 'none' | 'tight' | 'snug' | 'normal' | 'relaxed' | 'loose' | 'unset'
  hover?: boolean
  italics?: boolean
  underlined?: boolean
  lineThrough?: boolean
  lines?: number
  dangerouslySetInnerHTML?: any
  breakSpaces?: boolean
  width?: string
  maxWidth?: string
  children?: React.ReactNode | string
  fontFamily?: string
}

/**
 * Renders the Text Component
 * @param children
 * @param props
 * @returns
 */
export const Text: React.FC<TextProps> = memo(
  ({
    lineThrough,
    children = 'Text',
    className,
    as = 'p',
    ellipsis = false,
    lines = 0,
    breakSpaces = false,
    width = null,
    fontFamily = 'Lato',
    ...props
  }) => {
    return (
      <TextContainer
        as={as}
        className={className}
        ellipsis={ellipsis}
        lines={lines}
        breakSpaces={breakSpaces}
        width={width}
        fontFamily={fontFamily}
        {...props}
      >
        {lineThrough && <CustomLineThrough />}
        {children}
      </TextContainer>
    )
  }
)
