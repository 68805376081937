import { css } from 'styled-components'

export const handleCapitalize = (capitalize) => {
  return (
    capitalize &&
    css`
      text-transform: capitalize;
    `
  )
}

export const handleUppercase = (uppercase) => {
  return (
    uppercase &&
    css`
      text-transform: uppercase;
    `
  )
}

export const handleNoWrap = (noWrap) => {
  return (
    noWrap &&
    css`
      white-space: nowrap;
    `
  )
}

export const handleEllipsis = (ellipsis) => {
  return (
    ellipsis &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    `
  )
}

export const handleClickable = (clickable) => {
  return (
    clickable &&
    css`
      cursor: pointer;
    `
  )
}
