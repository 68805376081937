import { createContext, useLayoutEffect, useState } from 'react'
import { GET_USER_THEME, UserThemeGQLData } from 'graphql/queries/theme'
import { DEFAULT_THEME } from 'styles/themes/defaultTheme'
import { useQuery } from '@apollo/client'
import { useAuth } from './AuthContext'

export const PreferenceContext = createContext<PreferenceContextProps>(null)

export const PREFERENCE_SETTINGS = {
  sidebarExpanded: 'sidebarExpanded',
  notificationExpanded: 'notificationExpanded',
  hideProgress: 'hideProgress',
}

export const INITIAL_PREFERENCE_STATE = {
  sidebarExpanded: false,
  notificationExpanded: false,
  hideProgress: false,
}

export type PreferenceState = typeof INITIAL_PREFERENCE_STATE & {
  themeConfig: typeof DEFAULT_THEME
}

export type PreferenceContextProps = {
  updatePreferenceState: React.Dispatch<React.SetStateAction<PreferenceState>>
  updateProperty: (prop: keyof PreferenceState, newVal: any) => void
  preferenceState: PreferenceState | null
}

export const PreferenceContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { isLoggedIn } = useAuth()
  const { loading, data } = useQuery<UserThemeGQLData>(GET_USER_THEME, {
    skip: !isLoggedIn,
  })
  const localTheme = JSON.parse(localStorage?.getItem('themeConfig'))
  const [state, setState] = useState<PreferenceState>({
    ...INITIAL_PREFERENCE_STATE,
    themeConfig: localTheme || DEFAULT_THEME,
  })

  const updateProperty = (prop: keyof PreferenceState, newVal) => {
    // Default value for no local storage
    const value = newVal !== null ? newVal : false
    if (prop === 'themeConfig') {
      localStorage?.setItem('themeConfig', JSON.stringify(value))
    }
    if (prop === 'hideProgress') {
      localStorage?.setItem('hideProgress', value)
    }
    setState({
      ...state,
      [prop]: value,
    })
  }

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      const isThemeAlreadyApplied = state.themeConfig.name === localTheme?.name
      // Sets default theme
      if (localTheme && !isThemeAlreadyApplied) {
        updateProperty('themeConfig', localTheme)
      }
      // Sets default hide/show progress
      const hideProgress = localStorage?.getItem('hideProgress')
      hideProgress && updateProperty('hideProgress', hideProgress)
    }
  }, [])

  useLayoutEffect(() => {
    if (isLoggedIn === null) return
    if (!loading && data && isLoggedIn) {
      const userThemeData = data.currentUserData[0].setting.theme
      const isThemeAlreadyApplied =
        userThemeData.name === state.themeConfig.name
      if (!isThemeAlreadyApplied) {
        const newTheme = {
          ...state.themeConfig,
          ...userThemeData,
          configs: {
            ...state.themeConfig.configs,
            ...userThemeData.configs,
          },
          isDefault: DEFAULT_THEME.name === userThemeData.name,
        }
        updateProperty('themeConfig', newTheme)
      }
    }
    if (!isLoggedIn) {
      updateProperty('themeConfig', localTheme || DEFAULT_THEME)
    }
  }, [loading, data, isLoggedIn])

  return (
    <PreferenceContext.Provider
      value={{
        preferenceState: state,
        updatePreferenceState: setState,
        updateProperty,
      }}
    >
      {children}
    </PreferenceContext.Provider>
  )
}
