import styled from 'styled-components'

export const SplashLoaderContainer = styled.div<{
  $isLightTheme?: boolean
}>`
  background: ${({ theme, $isLightTheme }) =>
    $isLightTheme ? theme.highlight : theme.background.primary};
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`
