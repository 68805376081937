import { useContext, useLayoutEffect, useState } from 'react'
import { PreferenceContext } from 'contexts/PreferenceContext'
import { ThemeProvider } from 'styled-components'
import { DEFAULT_THEME } from 'styles/themes/defaultTheme'

export const ThemeWrapper = ({ children }) => {
  const { preferenceState } = useContext(PreferenceContext)
  const localTheme = JSON.parse(localStorage?.getItem('themeConfig'))
  const [theme, setTheme] = useState<typeof DEFAULT_THEME>(
    localTheme || DEFAULT_THEME
  )
  useLayoutEffect(() => {
    if (preferenceState?.themeConfig?.name === theme.name) return
    setTheme((val) => ({ ...val, ...preferenceState?.themeConfig }))
  }, [preferenceState?.themeConfig?.name])

  return (
    <ThemeProvider theme={theme.configs}>
      {preferenceState && children}
    </ThemeProvider>
  )
}
