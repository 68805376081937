import { FintrosLogo } from 'components/FintrosLogo'
import { SplashLoaderContainer } from './styles'
import BarLoader from 'react-spinners/BarLoader'
import { useTheme } from 'styled-components'

export const SplashLoader = () => {
  const theme = useTheme()
  return (
    <SplashLoaderContainer $isLightTheme={theme.is_light_mode}>
      <FintrosLogo animated size={150} />
      <BarLoader color={theme.white} />
    </SplashLoaderContainer>
  )
}
