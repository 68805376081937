import { SkeletonLine } from 'components/Skeleton'
import { DEFAULT_COMPANY_BANNER, DEFAULT_COMPANY_LOGO } from 'constants/images'
import NextImage from 'next/image'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { transition1 } from 'styles/transformation'
import { domains } from './domains'
import _isEmpty from 'lodash-es/isEmpty'

const fallbackMapper = {
  logo: DEFAULT_COMPANY_LOGO,
  banner: DEFAULT_COMPANY_BANNER,
}

const ImageWrapper = styled.div<{
  height?: number
  width?: number
  fullWidth?: boolean
  fullHeight?: boolean
  aspectRatio?: string
  position?: string
}>`
  position: ${({ position }) => position};
  z-index: 0;
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  aspect-ratio: ${({ aspectRatio }) => aspectRatio || 'unset'};
  overflow: hidden;

  min-width: ${({ width, fullWidth }) =>
    fullWidth ? '100%' : width ? width + 'px' : 'auto'} !important;

  min-height: ${({ height, fullHeight }) =>
    fullHeight ? '100%' : height ? height + 'px' : 'auto'} !important;

  > div {
    height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  }

  > span {
    position: unset !important;
    inset: unset !important;
  }
`

const StyledImage = styled.img<{
  height?: number
  width?: number
  $circle?: boolean
  $fullWidth?: boolean
  $fullHeight?: boolean
  $autoHeight?: boolean
  $autoWidth?: boolean
  $objectFitContain?: boolean
  $aspectRatio?: string
  $noBottomRadius?: boolean
  $zoomOnHover?: boolean
}>`
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || 'unset'};
  position: unset !important;
  margin: 0 !important;
  padding: 0 !important;
  height: ${({ height, $fullHeight, $autoHeight }) =>
    $fullHeight ? '100%' : $autoHeight ? 'auto' : height + 'px'} !important;
  width: ${({ width, $fullWidth, $autoWidth }) =>
    $fullWidth ? '100%' : $autoWidth ? 'auto' : width + 'px'} !important;
  min-height: ${({ height, $fullHeight, $autoHeight }) =>
    $fullHeight ? '100%' : $autoHeight ? 'auto' : height + 'px'} !important;
  min-width: ${({ width, $fullWidth, $autoWidth }) =>
    $fullWidth ? '100%' : $autoWidth ? 'auto' : width + 'px'} !important;
  border-radius: ${({ $circle }) => ($circle ? '100%' : '5px')};

  ${({ $noBottomRadius, $circle }) =>
    $noBottomRadius
      ? css`
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        `
      : css`
          border-radius: ${$circle ? '100%' : '5px'};
        `};

  ${({ $aspectRatio }) =>
    $aspectRatio &&
    css`
      aspect-ratio: ${$aspectRatio} !important;
      height: 100% !important;
      min-height: 100% !important;
    `};

  ${({ $zoomOnHover }) =>
    $zoomOnHover &&
    css`
      ${transition1};
      :hover {
        transform: scale(1.1);
      }
    `};

  object-fit: ${({ $objectFitContain }) =>
    $objectFitContain ? 'contain' : 'cover'};

  img {
    position: unset;
  }
`
export interface ImageProps {
  src: any
  height?: any
  width?: number
  circle?: boolean
  fullWidth?: boolean
  fullHeight?: boolean
  autoHeight?: boolean
  autoWidth?: boolean
  objectFitContain?: boolean
  aspectRatio?: string
  noBottomRadius?: boolean
  type?: 'logo' | 'banner'
  loading?: 'lazy' | 'eager'
  zoomOnHover?: boolean
  nextImagePriority?: boolean
  nextImageSizes?: string
  position?: 'absolute' | 'relative'
  showLoadingSkeleton?: boolean
}

export const Image: React.FC<ImageProps> = ({
  src,
  height = 50,
  width = 50,
  circle = false,
  fullWidth = false,
  fullHeight = false,
  autoHeight = false,
  autoWidth = false,
  objectFitContain = false,
  noBottomRadius = false,
  type = 'logo',
  aspectRatio,
  loading = 'lazy',
  zoomOnHover = false,
  nextImagePriority = false,
  nextImageSizes,
  position = 'relative',
  showLoadingSkeleton = true,
}) => {
  const fallbackImage = fallbackMapper[type]
  const [imageSrc, setImageSrc] = useState(_isEmpty(src) ? fallbackImage : src)
  const [imageLoaded, setImageLoaded] = useState(false)

  const getHostname = (url) => {
    try {
      return new URL(url).hostname
    } catch {
      return null
    }
  }

  // Extract host from url and compare with list of configured nextjs image hosts
  const hostname = getHostname(imageSrc) || ''
  const useNext = domains.includes(hostname) || imageSrc?.startsWith('/')

  const onImgLoadingError = (e) => {
    const imgEle = e.target as HTMLImageElement
    if (imgEle.src === DEFAULT_COMPANY_LOGO) return
    imgEle.src = fallbackImage
    setImageSrc(fallbackImage)
  }

  useEffect(() => {
    setImageSrc(_isEmpty(src) ? fallbackImage : src)
  }, [src])

  return (
    <ImageWrapper
      height={height}
      width={width}
      fullWidth={fullWidth}
      fullHeight={fullHeight}
      aspectRatio={aspectRatio}
      position={position}
    >
      {showLoadingSkeleton && !imageLoaded && (
        <SkeletonLine
          height="100% !important"
          width={fullWidth ? '100% !important' : `${width}px !important`}
          borderRadius={type === 'banner' ? '5px 5px 0px 0px' : ''}
          margin="0"
          position="absolute"
        />
      )}
      <StyledImage
        src={imageSrc}
        $aspectRatio={aspectRatio}
        alt="image"
        // Use next component if host is configured
        as={useNext ? NextImage : 'img'}
        onError={onImgLoadingError}
        onErrorCapture={onImgLoadingError}
        onLoad={() => setImageLoaded(true)}
        height={height}
        loading={useNext ? loading : null}
        priority={useNext && nextImagePriority}
        width={width}
        $circle={circle}
        $fullWidth={fullWidth}
        $fullHeight={fullHeight}
        $autoHeight={autoHeight}
        $autoWidth={autoWidth}
        fill={!!!width}
        $objectFitContain={objectFitContain}
        $noBottomRadius={noBottomRadius}
        // placeholder="blur"
        $zoomOnHover={zoomOnHover}
        sizes={nextImageSizes}
      />
    </ImageWrapper>
  )
}
